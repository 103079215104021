import type {
	AuthInvite,
	AuthLogin,
	AuthPasswordReset,
	AuthPasswordSet,
	AuthToken
} from "@/types/authentication";
import client from "./client";
import type { UserLoginResponse, User } from "@/types/user";

export const authentication = {
	login: async (details: AuthLogin): Promise<UserLoginResponse | undefined> => {
		const response = await client.post(`/auth/login/access`, details);
		return response.data as UserLoginResponse;
	},
	password: {
		reset: async (details: AuthPasswordReset): Promise<undefined> => {
			const response = await client.post(`/auth/resetpassword`, details);
			return response.data as undefined;
		},

		set: async (details: AuthPasswordSet): Promise<undefined> => {
			const response = await client.post(`/auth/setpassword`, details);
			return response.data as undefined;
		}
	},
	token: {
		validate: async (details: AuthToken): Promise<User | undefined> => {
			const response = await client.post(`/auth/validtoken`, details);
			return response.data as User;
		}
	},
	invite: {
		confirm: async (details: AuthInvite): Promise<string | undefined> => {
			const response = await client.post(`/auth/invite`, details);
			return response.data as string;
		}
	}
};

export default { authentication };
