import { orderBy } from "lodash";
import { isRef, ref, Ref, watch } from "vue";
import { unref, computed } from "vue";
import {
	ColumnDef,
	ComputedColumn,
	ComputedSort,
	RowDef,
	SortBy,
	SortDef,
	SortDirection
} from "./types";

type MaybeRef<T> = Ref<T> | T;

// Convert basic column definitions to full column definitions
export const useColumns = (columns: MaybeRef<ColumnDef[]>) => {
	const cols = computed(() =>
		unref(columns).map((col) => {
			const name = col.name ?? col.field.toString();
			return {
				...col,
				name,
				sort: col.sort ?? col.field,

				// slot names
				headerSlot: `${name}-header`,
				cellSlot: `${name}-col`
			};
		})
	);

	return { columns: cols };
};

// Apply sorting to the data
export const useSorts = (
	columns: MaybeRef<ColumnDef[]>,
	defaultSort: MaybeRef<SortDef | SortDef[] | undefined>,
	emit?: (event: "update:sort", ...args: any[]) => void
) => {
	const sorts = ref<SortDef[]>([]);

	// re-apply the default sort when it changes
	watch(
		() => unref(defaultSort),
		(sort) => {
			sorts.value = sort ? ( Array.isArray(sort) ? sort : [sort]) : [];
		},
		{ immediate: true }
	);

	// set the sorting, can be updated to support multi-column sorting
	const applySort = (name: string) => {
		const current = sorts.value[0];

		sorts.value = current && current.name === name
			? [{ name, direction: current.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC }]
			: [{ name, direction: SortDirection.ASC }]

		emit && emit("update:sort", sorts.value);
	};

	// check if the field is being sorted on
	const activeInfo = (name: string) => {
		const active = sorts.value.find((s) => s.name === name);

		return { selected: !!active, direction: active?.direction };
	};

	// map sort definitions to something that can be used by lodash orderBy function
	const computedSorts = computed<ComputedSort>(() =>
		sorts.value.reduce(
			(carry, sort) => {
				const col = unref(columns).find((c) => c.name === sort.name);
				const field = col?.sort ?? sort.name;
				const direction = sort.direction;

				carry[0].push(field);
				carry[1].push(direction);
				return carry;
			},
			[[], []] as [SortBy[], SortDirection[]]
		)
	);

	return { sorts, computedSorts, applySort, activeInfo };
};

// Apply pagination to the data
export const useLocalPagination = (
	data: MaybeRef<RowDef[]>,
	perPage: MaybeRef<number | false>,
	sort: MaybeRef<ComputedSort>,
	emit: (event: "update:page", ...args: any[]) => void
) => {
	const page = ref(1);
	const setPage = (pageNumber: number) => {
		page.value = pageNumber;
		emit("update:page", pageNumber);
	};

	// apply sorting and pagination to the data
	const paginatedData = computed(() => {
		const originalData = unref(data);
		const [fields, directions] = unref(sort);
		let itemsPerPage = unref(perPage);

		let ordered = orderBy(originalData, fields, directions);
		if (!!itemsPerPage) {
			const offset = (page.value - 1) * itemsPerPage;
			ordered = ordered.slice(offset, offset + itemsPerPage);
		}

		return ordered
	});

	const total = computed(() => unref(data).length);
	const totalPages = computed(() => {
		let itemsPerPage = unref(perPage);
		if (!itemsPerPage) {
			return 1;
		}

		return itemsPerPage > 0 ? Math.ceil(unref(total) / itemsPerPage) : 1;
	});
	const hasPrevPage = computed(() => page.value > 1);
	const hasNextPage = computed(() => page.value < totalPages.value);
	const shouldShowPagination = computed(() => totalPages.value > 1);

	// reset the page when the sort changes
	watch(() => unref(sort), () => setPage(1));

	return {
		page,
		paginatedData,
		total,
		totalPages,
		hasPrevPage,
		hasNextPage,
		shouldShowPagination,
		setPage
	};
};
