import numeral from "numeral";
import { format, isToday, isAfter, isValid, isYesterday, isThisYear } from "date-fns";

export function countPlaces(num: number) {
	const sep = ".";
	const b = String(num).split(sep);
	return b[1] ? b[1].length : 0;
}

function zeroGeneration(count: number) {
	let i = 0;
	const zeros = [];
	while (i < count) {
		zeros.push("0");
		i++;
	}
	return zeros.join("");
}

const THOUSANDS_FORMAT = "$0a"; // 230_000 -> $230k
const MILLIONS_FORMAT = "$0[.][00]a"; // 1_230_000 -> $1.23m

export function shortenNumber(num: number): string {
	const formatter = num >= 1_000_000 ? MILLIONS_FORMAT : THOUSANDS_FORMAT;
	return numeral(num).format(formatter);
}

export const date_local = (date: Date | string) => {
	return format(new Date(date), "dd/MM/yyyy");
};

export const date_long = (date: Date | string) => {
	return format(new Date(date), "d MMM yyyy");
};

export const date_month = (date: Date | string) => {
	return format(new Date(date), "MMMM yyyy");
};

export const date_time = (date: Date | string) => {
	date = new Date(date);
	const label = format(date, "d MMM, h:mma");
	return `${
		isToday(date)
			? `Today, ${format(date, "h:mma")}`
			: isYesterday(date)
			? `Yesterday, ${format(date, "h:mma")}`
			: !isThisYear(date)
			? format(date, "d MMM yyyy, h:mma")
			: `${label}`
	}`;
};

export const date_time_object = (date: Date) => {
	const time = format(date, "h:mma");
	const day = `${
		isToday(date)
			? `Today`
			: isYesterday(date)
			? `Yesterday`
			: format(date, "d MMM yyyy")
	}`;
	return { day, time };
};

export const date_server = (date: Date) => {
	return format(date, "yyyy-MM-dd");
};

export function distanceFormat(num: number) {
	return numeral(num).format("0,0.0");
}

export function currency(num: number, $fmt = "$0,0") {
	return numeral(num).format($fmt);
}

export function formatFrequency(word: string): string {
	return word.length
		? "/" + word.toLowerCase().replace("ly", "")
		: "/not specified";
}

export function shortenGender(word: string): string {
	const words = word.split(" or ");
	return words.length > 1
		? words.map((w) => w.split("")[0]).join("/")
		: words.map((w) => w.split("", 1)).toString();
}

export const is_date_set = (date: string | Date) => {
	return (
		isAfter(new Date(date), new Date("1975-01-01")) && isValid(new Date(date))
	);
};

export function pageName(name: string): string {
	return name
		.split("-")
		.map((segment) => {
			return segment.charAt(0).toUpperCase() + segment.slice(1);
		})
		.join("");
}

export const gtmTag = (name: string) => {
	const gtmName = name
		.replace(/\d/g, "")
		.split("/")
		.filter((name) => name)
		.join("-");
	return `gtm-${gtmName}`;
};
