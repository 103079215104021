import type { VacancyType } from "@/types/listing";
import type { VacanciesSortOptions, SortOrder } from "@/types/tables";
import { lastUpdate, vacancyTally } from "@/plugins/vacancies";
import { listingsStore } from "./listings";
import { ParseFail } from "@/models/parseFail";
import { defineStore } from "pinia";
import { listing } from "@/api/listing.api";
import { useToast } from "vue-toastification";
import { permanentTypes, respiteTypes } from "@/meta/vacancy";
import { orderBy } from "lodash";
import { SortDef, SortDirection } from '@/components/Table/types'

const toast = useToast();

interface VacanciesState {
	vacancies: VacancyType[];
	modal: boolean;
	sort: SortDef;
}

export const state = (): VacanciesState => ({
	vacancies: [],
	modal: false,
	sort: { name: "title", direction: SortDirection.ASC }
});

export const vacanciesStore = defineStore("vacancies", {
	state,
	getters: {
		summary: (state) => {
			const permanent = state.vacancies.filter((vacancy) =>
				permanentTypes.includes(vacancy.accomodation)
			);
			const respite = state.vacancies.filter((vacancy) =>
				respiteTypes.includes(vacancy.accomodation)
			);
			const list = listingsStore().listings.map((listing) => {
				const { id, title, suburb, state } = listing;
				return {
					id,
					title,
					suburb,
					state,
					updated: lastUpdate(permanent, id),
					available: vacancyTally(permanent, id),
					expected: vacancyTally(permanent, id, "expected"),
					respite: vacancyTally(respite, id)
				};
			});
			return orderBy(list, [state.sort], [state.orderBy]);
		}
	},
	actions: {
		async setAllCurrent(userid: number) {
			const results = await listing.vacancies.setAllCurrent(userid);

			if (!ParseFail.isFailure(results)) {
				const { listingsReconfirmed: count, actions } = results;
				// Update the listings in the listing store
				await listingsStore().$patch((state) => (state.actions = actions));
				// We need to update vacancy.updated manually as the reponse only contains
				// basic information.
				this.vacancies.forEach((vacancy) => {
					vacancy.updated = new Date();
				});

				return toast.success(
					`All vacancies have been reconfirmed for ${count} listings`
				);
			}

			return toast.error(`Could not reconfirm listings at this time`);
		},
		async exportPDF(orgId: number, listingids: number[], type = "Standard") {
			await listing.vacancies.vacancyPDF(orgId, listingids, type.toLowerCase());

			// Inform the user something has happened.
			toast.success(
				"Your PDF is being generated and will be emailed to you shortly"
			);
			// now its complete we can close the modal from here.
			this.modal = false;
		}
	}
});
