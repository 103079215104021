import { ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { features } from '@/api/features.api'

type Features = Set<string>

async function getFeatures(token?: string): Promise<Features> {
  const featureSet = new Set<string>()
  if (token) {
    try {
      const { data } = await features.list()
      for (const [key] of Object.entries(data))
        featureSet.add(key)
    }
    catch (e) {
      console.error('Failed to fetch feature flags', { error: e })
    }
  }

  return featureSet
}

export const useFeaturesStore = defineStore('features', () => {
  const loaded = ref(false)
  const features = ref<Features>(new Set())
  const token = useLocalStorage<string | undefined>('token', undefined, { writeDefaults: false })

  // update the features when the token changes
  watch(token, async (token) => {
    loaded.value = false
    features.value = await getFeatures(token)
    loaded.value = true
  })

  /** check if the feature is enabled */
  const featureEnabled = (name: string) => features.value.has(name) ?? false

  /** ensure features have been loaded from the API */
  const ensureLoaded = async () => {
    if (!loaded.value) {
      features.value = await getFeatures(token.value)
      loaded.value = true
    }
  }

  return { loaded, features, featureEnabled, ensureLoaded }
})
