export type EventTypes = "auth" | "route" | "listingUpdate" | "roomUpdate" | "vacancyUpdate";

export type DataObject = {
	event: EventTypes;
 	updateType: string;
 	value: string | boolean | number | object;
};

/**
 * Push a data object to the GTM dataLayer.
 */
const push = (data: DataObject) => {
	(window as any).dataLayer = (window as any).dataLayer || [];
	(window as any).dataLayer.push(data);
}

export default { push }
