import type { NavigationGuardNext, RouteLocationRaw, RouteRecordNormalized, Router } from 'vue-router'
import { useFeaturesStore } from '../../stores/features'

type FailedFlags = { flag: string; redirect?: RouteLocationRaw }[]

function mapFeatures(matched: RouteRecordNormalized[], checker: (name: string) => boolean) {
  return matched.reduce((flags, route) => {
    const features = route.meta?.features
    const redirect = route.meta?.featuresRedirect

    if (features) {
      features.forEach((flag, i) => {
        const r = typeof redirect === 'string' ? redirect : redirect?.[i]

        if (!checker(flag))
          flags.push({ flag, redirect: r })
      })
    }

    return flags
  }, [] as FailedFlags)
}

export function checkFeatureFlags(matched: RouteRecordNormalized[], next: NavigationGuardNext, store: ReturnType<typeof useFeaturesStore>) {
  const flags = mapFeatures(matched, store.featureEnabled)
  if (flags.length)
    return next(flags[0].redirect ?? { name: 'four.o.four' })

  next()
}

export function applyFeatureToggleMiddleware(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const store = useFeaturesStore()
    await store.ensureLoaded()

    checkFeatureFlags(to.matched, next, store)
  })
}
