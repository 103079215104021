<template>
	<button
		class="button"
		:class="[
			{ 'is-loading': isLoading },
			{ 'is-successful': isSuccess },
			{ 'is-error': isError }
		]"
		@click.prevent="triggerAction"
	>
		<slot></slot>
	</button>
</template>

<script lang="ts">
export default {
	name: "ActionButton"
};
</script>

<script lang="ts" setup>
import { ref } from "vue";
import { Staller } from "@/plugins/staller";
import type { DailyCoreError } from "@/types/xhr-client";

const props = defineProps({
	onClick: Function
});

const emits = defineEmits<{
	(e: "success"): void;
	(e: "error", value: DailyCoreError): void;
}>();

let isLoading = ref(false);
let isSuccess = ref(false);
let isError = ref(false);

const triggerAction = async () => {
	if (props.onClick) {
		const staller = new Staller();
		isLoading.value = true;
		await props
			.onClick()
			.then(async () => {
				await staller.stop();
				isLoading.value = false;
				isSuccess.value = true;
				await staller.delay();
				isSuccess.value = false;
				emits("success");
			})
			.catch(async (error: DailyCoreError) => {
				await staller.stop();
				isLoading.value = false;
				emits("error", error);
				isError.value = true;
				await staller.delay(2000);
				isError.value = false;
			});
	} else {
		console.warn("No onClick has been provided to component");
	}
};
</script>

<style lang="postcss" scoped>
.button {
	@apply relative border border-slate-200 hover:border-slate-300 rounded py-2 px-4;
}
.button.is-loading,
.button.is-successful,
.button.is-error {
	@apply cursor-default text-transparent;
}
.button.is-successful::after,
.button.is-error::after {
	@apply absolute text-white text-xl font-normal inset-0 top-1.5;
	font-family: "Font Awesome 5 Pro";
	content: "\f00c";
}

.button.is-loading::after {
	@apply animate-spin;
	border: 2px solid #fff;
	border-radius: 290486px;
	border-right-color: transparent;
	border-top-color: transparent;
	content: "";
	display: block;
	height: 1em;
	width: 1em;
	left: calc(50% - (1em * 0.5));
	top: calc(50% - (1em * 0.5));
	position: absolute !important;
}
.button.is-successful::after {
	content: "\f00c";
}
.button.is-error {
	@apply bg-red-600;
}
.button.is-error::after {
	content: "\f00d";
}
</style>
