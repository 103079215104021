
export type Range<S = number | null> = {
  start: string | null
  end: string | null
  shortcut: S
}

export type Shortcut = {
  key: string
  label: string
  value: string | number
  callback?: (range: Range<Shortcut>) => void;
}


export function generateShortcuts(callback: (shortcut: Shortcut) => void): Shortcut[] {
  return [
		{
			key: "last7Days",
			label: "Last 7 Days",
			value: 7,
			callback: ({ shortcut }) => callback(shortcut)
		},
		{
			key: "last14Days",
			label: "Last 14 Days",
			value: 14,
			callback: ({ shortcut }) => callback(shortcut)
		},
		{
			key: "last30Days",
			label: "Last 30 Days",
			value: 30,
			callback: ({ shortcut }) => callback(shortcut)
		},
		{
			key: "last60Days",
			label: "Last 60 Days",
			value: 60,
			callback: ({ shortcut }) => callback(shortcut)
		},
		{
			key: "last90Days",
			label: "Last 90 Days",
			value: 90,
			callback: ({ shortcut }) => callback(shortcut)
		},
		{
			key: "last180Days",
			label: "Last 180 Days",
			value: 180,
			callback: ({ shortcut }) => callback(shortcut)
		},
		{
			key: "last12Months",
			label: "Last 12 Months",
			value: 365,
			callback: ({ shortcut }) => callback(shortcut)
		}
	]
}
