import { ParseFail } from "../parseFail";
import { Actions } from "../actions";

export interface VacancyConfirmActions {
	canReconfirmAll: boolean;
}

export class ReconfirmAllResult {
	public static parseApi(data: any): ReconfirmAllResult | ParseFail {
		if (data.listingsReconfirmed === undefined) {
			return new ParseFail("listingsReconfirmed missing from response");
		}

		if (data.actions === undefined) {
			return new ParseFail("actions missing from response");
		}

		const actions = Actions.parseApi(data.actions);

		if (ParseFail.isFailure(actions)) {
			return actions;
		}

		const vacanciesActions = {
			canReconfirmAll: actions.isEnabled("reconfirm-all")
		};

		return new ReconfirmAllResult(data.listingsReconfirmed, vacanciesActions);
	}
	public readonly listingsReconfirmed: number;
	public readonly actions: VacancyConfirmActions;

	constructor(listingsReconfirmed: number, actions: VacancyConfirmActions) {
		this.listingsReconfirmed = listingsReconfirmed;
		this.actions = actions;
	}
}
