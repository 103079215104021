import type { DailyCoreActions } from "@/types/listing";
import { ParseFail } from "./parseFail";

export class Actions {
	public static parseApi(data: DailyCoreActions): Actions | ParseFail {
		if (Array.isArray(data)) {
			const actions = new Actions();

			data.forEach((value) => {
				const id = value.id;
				const reason = value.reason;
				if (!id) return new ParseFail("invalid action returned");
				actions.add(id, reason);
			});

			return actions;
		}

		return new ParseFail("no actions array present");
	}

	private actions: object;
	constructor() {
		this.actions = {};
	}

	public isEnabled(name: string): boolean {
		for (const [key, value] of Object.entries(this.actions)) {
			if (key === name && value) return true;
		}
		return false;
	}

	public add(name: string, disabledReason: string) {
		const enabled = !disabledReason;
		console.log("Adding action: ", name, " enabled: ", enabled);
		this.actions = {
			...this.actions,
			[name]: enabled
		};
	}
}
