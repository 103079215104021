<script lang="ts">
export default {
	name: "AppReportsHollistics"
};
</script>

<script lang="ts" setup>
import type { Report } from "@/types/dashboard";

interface propsType {
	reports?: Report[];
}

const props = withDefaults(defineProps<propsType>(), {
	reports: () => []
});

const dashboardFrameHeight = 5500;

const styles = {
	width: "100%",
	height: `${dashboardFrameHeight}px`
};
</script>

<template>
	<div v-for="({ name, url }, index) in reports" :key="index">
		<iframe :src="url" :title="name" frameborder="0" :style="styles"></iframe>
	</div>
</template>
