import type {
	Listing,
	ListingsActions,
	ListingsResponse
} from "@/types/listing.d";
import { Actions } from "../actions";
import { ParseFail } from "../parseFail";

export class ListingsCollection {
	public static parseApi(
		data: Listing[] | ListingsResponse
	): ListingsCollection | ParseFail {
		if (Array.isArray(data)) {
			// Old format, no actions structure
			return new ListingsCollection(data, { canReconfirmAll: false });
		}

		// New format, endpoint is returning an object. Make sure we have what we expect
		const listings = data.listings;

		if (!Array.isArray(listings)) {
			return new ParseFail("listings is not an array");
		}

		const actions = Actions.parseApi(data.actions);

		if (ParseFail.isFailure(actions)) {
			return actions;
		}

		const collectionActions = {
			canReconfirmAll: actions.isEnabled("reconfirm-all")
		};

		return new ListingsCollection(listings, collectionActions);
	}

	public static empty(): ListingsCollection {
		return new ListingsCollection([], { canReconfirmAll: false });
	}

	public entries: Listing[];
	public actions: ListingsActions;

	constructor(listings: Listing[], actions: ListingsActions) {
		this.entries = listings;
		this.actions = actions;
	}

	public filterActiveSubscribers(): ListingsCollection {
		return new ListingsCollection(
			this.entries.filter(
				(listing) =>
					listing.status === "active" && !["Basic"].includes(listing.plan)
			),
			this.actions
		);
	}
}
