import { createRouter, createWebHistory } from "vue-router";
import { setupLayouts } from "layouts-generated";
import generatedRoutes from "pages-generated";
import { authStore } from "@/stores/authentication";
import redirects from './redirects'
import { applyFeatureToggleMiddleware } from './middleware/features'

const routes = setupLayouts(generatedRoutes);

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [...routes, ...redirects],
	scrollBehavior(to) {
		if (to.hash) {
			return {
				el: to.hash,
				top: 64,
				behavior: "smooth"
			};
		}
		// always scroll to top
		return { top: 0 };
	}
});

applyFeatureToggleMiddleware(router);

// Little check to make sure we're not using cypress test suite for checking the site
if (!window.Cypress) {
	// Some middleware to help us ensure the user is authenticated.
	// Put noAuth: true on only route that doesn't require auth
	router.beforeEach((to, from, next) => {
		// If they aren't logged in then, redirect to /login and set query for redirect
		const noAuth = to.meta && to.meta.noAuth;
		// For some reason we are getting a double redirect to the login page.
		const isLoginPage = to.name === "Login";

		if (!isLoginPage && !noAuth && !authStore().isAuthenticated) {
			// IE/Edge fix. They are setting the no / path to be '/undefined'
			const nextPath = to.fullPath === "/undefined" ? undefined : to.fullPath;
			next({
				name: "login",
				query: {
					redirect: nextPath
				}
			});
		} else {
			if (to.meta.title) {
				if (typeof to.meta.title === "string") {
					document.title = `DailyAccess - ${to.meta.title}`;
				}
			} else {
				document.title = `DailyAccess`;
			}
			next();
		}
	});
}
export default router;
