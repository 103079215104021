import type { Organisation } from "@/types/organisation.d";
import type { User } from "@/types/user";
import { defineStore } from "pinia";
import { organisation as api } from "@/api/organisation.api";

interface OrganisationState {
	users: User[] | [];
	organisation: Organisation | undefined;
}

export const state = (): OrganisationState => ({
	users: [],
	organisation: undefined
});

export const organisationStore = defineStore("organisation", {
	state,
	actions: {
		async prepare() {
			if (this.organisation) {
				this.users = await api.users(this.organisation.id);
			}
		}
	}
});
