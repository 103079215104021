<template>
	<thead>
		<tr>
			<slot></slot>
		</tr>
	</thead>
</template>

<script lang="ts">
export default {
	name: "TableHead"
};
</script>
