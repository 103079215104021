<script lang="ts" setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { listingsStore } from "@/stores/listings";
import { vacanciesStore } from "@/stores/vacancies";

const { actions } = storeToRefs(listingsStore());
const { setAllCurrent } = vacanciesStore();

const props = defineProps({
	disabled: { type: Boolean, default: false },
	userId: { type: Number, required: true }
});

const canReconfirm = computed(
	() => !props.disabled && actions.value.canReconfirmAll
);
const confirmAllListings = async () => setAllCurrent(props.userId);
</script>

<template>
	<app-action-button
		class="is-primary gtm-refresh-all-vacancies-all-facilities"
		:disabled="!canReconfirm"
		data-test="reconfirm-all-vacancies"
		@click="confirmAllListings"
	>
		<span v-if="canReconfirm">
			<i class="fal fa-sync-alt mr-2"></i>
			<span>Reconfirm all vacancies</span>
		</span>
		<span v-else>
			<i class="fal fa-check mr-2"></i>
			<span>Vacancies reconfirmed for today</span>
		</span>
	</app-action-button>
</template>
