import type {
	AuthLogin,
	AuthPasswordReset,
	AuthToken,
	AuthPasswordSet
} from "@/types/authentication";
import { userStore } from "./user";
import { defineStore, acceptHMRUpdate } from "pinia";
import { authentication as api } from "../api/authentication.api";
import { organisationStore } from "./organisation";
import datalayer from '@/plugins/datalayer'

interface AuthState {
	token: string | undefined;
}

export const state = (): AuthState => ({
	token: undefined
});

export const TokenType = {
	invite: "invite",
	passwordReset: "password_reset"
};

export const authStore = defineStore("authentication", {
	state,
	actions: {
		async login(details: AuthLogin) {
			const response = await api.login(details);
			if (response) {
				// set the token for the store to distribute and add to localStorage
				if (response.token) {
					this.setToken(response.token);
					this.storeToken(response.token);
				}

				const uStore = userStore()
				// patching the userStore with returned user and dashboard items
				uStore.$patch({
					user: response.user,
					config: response.config,
					intercom: response.intercom
				});

				if (response.organisation) {
					organisationStore().$patch({ organisation: response.organisation });
				}

				// Once we have set the above we need to load the users config,
				// vacancies and listings information
				await uStore.loadExtras();
				datalayer.push({ event: 'auth', updateType: 'login', value: uStore.gtmData as object })
			}
		},
		async logout() {
			// resetting this store back to its initial state.
			this.$reset();
			// resetting the user store
			userStore().$reset();
			//  clearing localStorage
			window.localStorage.clear();

			if ((window as any).Intercom) {
				(window as any).Intercom("shutdown");
				(window as any).Intercom("boot", {
					app_id: import.meta.env.VITE_APP_INTERCOM_APPID
				});
			}

			datalayer.push({ event: 'auth', updateType: 'logout', value: true })
		},
		async forgottenPassword(details: AuthPasswordReset) {
			const response = await api.password.reset(details);
			return response;
		},
		async setPassword(details: AuthPasswordSet) {
			const response = await api.password.set(details);
			return response;
		},
		setToken(token: string) {
			this.token = token;
		},
		storeToken(token: string) {
			if (window.localStorage) {
				window.localStorage.setItem("token", token);
			}
		},
		storeImpersonation() {
			if (window.localStorage) {
				window.localStorage.setItem("impersonation", "true");
			}
		},
		async validate(details: AuthToken) {
			const response = await api.token.validate(details);
			return response;
		},
		async become(token: string, impersonate: boolean): Promise<void> {
			if (token) {
				this.setToken(token);
				this.storeToken(token);
				if (impersonate) {
					this.storeImpersonation();
				}
				await userStore().fetch();
			}
		}
	},
	getters: {
		isAuthenticated(state): boolean {
			return !!state.token as boolean;
		}
	}
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(authStore, import.meta.hot));
}
