<script lang="ts" setup>
import type { PropType } from "vue";
import { SortDirection } from "../types";

const props = defineProps({
	selected: { type: Boolean, default: false },
	direction: {
		type: String as PropType<SortDirection>,
		default: SortDirection.ASC
	},
	sortable: { type: Boolean, default: true }
});

const emit = defineEmits(["sort"]);

const sort = () => {
	if (props.sortable) {
		emit("sort");
	}
};
</script>

<template>
	<th
		data-test="table-head-cell"
		:class="['relative', { active: selected, sortable }]"
		@click="sort"
	>
		<span>
			<slot />
		</span>

		<span
			v-show="selected"
			class="absolute bottom-0 right-1 -translate-y-1/2 transition-transform ml-2"
			:class="{ 'rotate-180': direction === SortDirection.DESC }"
		>
			<i class="fas fa-arrow-down" />
		</span>
	</th>
</template>

<style lang="postcss" scoped>
th {
	@apply leading-4 align-bottom;
}

th.sortable {
	@apply hover:border-b-gray-600 cursor-pointer;
}

th.active {
	@apply border-b-gray-600;
}
</style>
