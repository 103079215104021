import { ReconfirmAllResult } from "./../models/vacancies/reconfirmAllResult";
import type { Listing, ListingActivity, VacancyType } from "@/types/listing";
import type { MediaImage } from "@/types/media";
import type { Room } from "@/types/rooms";
import client from "./client";

export const listing = {
	get: async (id: number): Promise<Listing> => {
		const response = await client.get(`/listings/${id}`);
		return response.data as Listing;
	},
	update: async (listing: Listing): Promise<Listing> => {
		const { id } = listing;
		const response = await client.put(`/listings/${id}`, listing);
		return response.data as Listing;
	},
	history: {
		get: async (id: number): Promise<ListingActivity[]> => {
			const response = await client.get(`/listings/${id}/history`);
			return (response.data || []) as ListingActivity[];
		}
	},
	media: {
		update: async (id: number, type: string, media: MediaImage[]) => {
			const response = await client.post(`/listings/${id}/${type}`, media);
			return response.data;
		}
	},
	rooms: {
		get: async (id: number): Promise<Room[]> => {
			const response = await client.get(`/listings/${id}/rooms`);
			return (response.data || []) as Room[];
		}
	},
	room: {
		create: async (room: Room): Promise<Room> => {
			const response = await client.post(`/rooms`, room);
			return response.data as Room;
		},
		get: async (id: number): Promise<Room> => {
			const response = await client.get(`/rooms/${id}`);
			return response.data as Room;
		},
		update: async (vacancy: Room): Promise<Room> => {
			const { id } = vacancy;
			const response = await client.put(`/rooms/${id}`, vacancy);
			return response.data as Room;
		},
		delete: async (vacancy: Room) => {
			const { id } = vacancy;
			const response = await client.delete(`/rooms/${id}`);
			return response.data;
		}
	},
	vacancies: {
		get: async (id: number): Promise<VacancyType[]> => {
			const response = await client.get(`/listings/${id}/vacancytypes`);
			return (response.data || []) as VacancyType[];
		},
		setcurrent: async (id: number) => {
			const response = await client.post(
				`/listings/${id}/vacancytypes/current`
			);
			return response.data;
		},

		setAllCurrent: async (id: number) => {
			const response = await client.post(
				`/users/${id}/listings/reconfirmations`
			);
			return ReconfirmAllResult.parseApi(response.data);
		},
		vacancyPDF: async (
			organisationid: number,
			listingids: number[],
			type = "standard"
		) => {
			const response = await client.post(
				`/organisations/${organisationid}/listings/vacancyemail.pdf?${type}=1`,
				listingids,
				{
					responseType: "blob"
				}
			);
			return response.data;
		},
		compactPDF: async (organisationid: number, listingids: number[]) => {
			const response = await client.post(
				`/organisations/${organisationid}/listings/vacancyemail.pdf?compact=1`,
				listingids,
				{
					responseType: "blob"
				}
			);
			return response.data;
		}
	},
	vacancy: {
		create: async (vacancy: VacancyType): Promise<VacancyType> => {
			const response = await client.post(`/vacancytypes`, vacancy);
			return response.data as VacancyType;
		},
		get: async (id: number): Promise<VacancyType> => {
			const response = await client.get(`/vacancytypes/${id}`);
			return response.data as VacancyType;
		},
		update: async (vacancy: VacancyType): Promise<VacancyType> => {
			const { id } = vacancy;
			const response = await client.put(`/vacancytypes/${id}`, vacancy);
			return response.data as VacancyType;
		},
		delete: async (vacancy: VacancyType) => {
			const { id } = vacancy;
			const response = await client.delete(`/vacancytypes/${id}`);
			return response.data;
		}
	}
};
