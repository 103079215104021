import type { ListingsCollection } from "@/models/listings/collection";
import type { Listing, ListingsActions } from "@/types/listing";
import { defineStore } from "pinia";
import { ParseFail } from "@/models/parseFail";
import { orderBy } from "lodash";
import { SortDef, SortDirection } from "@/components/Table/types";

interface ListingsState {
	listings: Listing[];
	actions: ListingsActions;
	sort: SortDef;
}

export const state = (): ListingsState => ({
	listings: [],
	actions: { canReconfirmAll: false },
	sort: { name: "title", direction: SortDirection.ASC }
});

export const listingsStore = defineStore("listings", {
	state,
	getters: {
		notifications: (state) => {
			const notifications = state.listings
				.filter((listing) => listing.notification)
				.map((listing) => {
					return {
						title: listing.title,
						params: { id: listing.id }
					};
				});
			return notifications;
		},
	},
	actions: {
		async set(collection: ListingsCollection | ParseFail) {
			if (!ParseFail.isFailure(collection)) {
				this.listings = collection.entries;
				this.actions = collection.actions;
			}
		}
	}
});
