<script lang="ts" setup>
import type { PropType } from "vue";
import { toRefs } from "vue";
import type { ColumnDef, RowDef, SortDef } from "./types";
import { useColumns, useSorts, useLocalPagination } from "./composibles";

const props = defineProps({
	idKey: { type: String, default: "id" },
	data: { type: Array as PropType<RowDef[]>, required: true },
	columns: { type: Array as PropType<ColumnDef[]>, required: true },
	perPage: { type: [Number, Boolean] as PropType<number | false>, default: 20 },
	sort: { type: [Object, Array] as PropType<SortDef | SortDef[]> },
	noDataLabel: { type: String, default: "No Data Found" }
});
const { data, columns: propColumns, perPage, sort } = toRefs(props);

const emit = defineEmits(["update:sort", "update:page"]);

const { columns } = useColumns(propColumns);
const { computedSorts, applySort, activeInfo } = useSorts(columns, sort, emit);
const { paginatedData, shouldShowPagination, total, page, setPage } =
	useLocalPagination(data, perPage, computedSorts, emit);
</script>

<template>
	<table v-bind="$attrs" class="table w-full">
		<table-head>
			<table-head-cell
				v-for="col of columns"
				:key="col.name"
				:class="col.headerClass"
				:sortable="!!col.sort"
				v-bind="activeInfo(col.name.toString())"
				@sort="applySort(col.name.toString())"
			>
				<slot :name="col.headerSlot" :col="col"
					><span>{{ col.label }}</span></slot
				>
			</table-head-cell>
		</table-head>

		<tbody>
			<slot
				name="row"
				v-for="row of paginatedData"
				:key="row[idKey]"
				:row="row"
				:columns="columns"
			>
				<tr>
					<td v-for="col of columns" :key="col.name" :class="col.class">
						<slot
							:name="col.cellSlot"
							:row="row"
							:col="col"
							:value="row[col.field.toString()]"
						>
							<span>{{ row[col.field.toString()] }}</span>
						</slot>
					</td>
				</tr>
			</slot>

			<tr v-if="!total" class="bg-gray-50" data-test="table-empty-slot">
				<td colspan="100%" class="py-5">
					<slot name="empty">
						<div class="text-center mx-auto">
							<span class="icon">
								<i class="fas fa-2x fa-box-open"></i>
							</span>
							<p class="is-size-5">{{ noDataLabel }}</p>
						</div>
					</slot>
				</td>
			</tr>
		</tbody>
	</table>

	<div v-if="shouldShowPagination" class="level">
		<div class="level-left">
			<slot name="pagination-left" />
		</div>

		<div class="level-right">
			<slot
				name="pagination-right"
				:total="total"
				:page="page"
				:per-page="perPage"
				:change="setPage"
			>
				<app-pagination
					:total="total"
					:current="page"
					:per-page="perPage"
					@change="setPage"
				/>
			</slot>
		</div>
	</div>
</template>
