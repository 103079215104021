import client from "./client";

export const organisation = {
	get: async (id: number) => {
		const response = await client.get(`/organisations/${id}`);
		return response.data;
	},
	users: async (id: number) => {
		const response = await client.get(`/organisations/${id}/users`);
		return response.data;
	},
	listings: async (id: number) => {
		const response = await client.get(`/organisations/${id}/listings`);
		return response.data;
	}
};

export default organisation;
