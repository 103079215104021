<template>
	<nav class="pagination">
		<button
			:disabled="!hasPrev"
			class="pagination-link pagination-previous"
			@click="changePage(current - 1)"
			data-test="previous"
		>
			<i class="fas fa-chevron-left"></i>
		</button>
		<button
			class="pagination-link pagination-next"
			@click="changePage(current + 1)"
			data-test="next"
		>
			<i class="fas fa-chevron-right"></i>
		</button>
		<ul class="pagination-list">
			<li v-if="hasFirst" data-test="has-first">
				<button class="pagination-link" @click="changePage(1)">1</button>
			</li>
			<li v-if="hasFirstEllipsis" data-test="has-first-ellipsis">
				<span class="pagination-ellipsis">&hellip;</span>
			</li>
			<li v-for="page in pagesInRange" :key="page.number">
				<button
					class="pagination-link"
					:class="{
						'is-current': page.isCurrent
					}"
					@click="page.click"
					data-test="pages-in-range"
				>
					{{ page.number }}
				</button>
			</li>
			<li v-if="hasLastEllipsis" data-test="has-last-ellipsis">
				<span class="pagination-ellipsis">&hellip;</span>
			</li>
			<li v-if="hasLast" data-test="has-last">
				<button class="pagination-link" @click="changePage(pageCount)">
					{{ pageCount }}
				</button>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: "AppPagination",
	props: {
		total: {
			type: [Number, String],
			default: () => 0
		},
		perPage: {
			type: [Number, String],
			default: 20
		},
		current: {
			type: [Number, String],
			default: 1
		},
		rangeBefore: {
			type: [Number, String],
			default: 1
		},
		rangeAfter: {
			type: [Number, String],
			default: 1
		}
	},
	computed: {
		beforeCurrent() {
			return parseInt(this.rangeBefore);
		},

		afterCurrent() {
			return parseInt(this.rangeAfter);
		},
		pageCount() {
			return Math.ceil(this.total / this.perPage);
		},
		/**
		 * Check if previous button is available.
		 */
		hasPrev() {
			return this.current > 1;
		},
		/**
		 * Check if first page button should be visible.
		 */
		hasFirst() {
			return this.current >= 2 + this.beforeCurrent;
		},
		/**
		 * Check if first ellipsis should be visible.
		 */
		hasFirstEllipsis() {
			return this.current >= this.beforeCurrent + 4;
		},

		/**
		 * Check if last page button should be visible.
		 */
		hasLast() {
			return this.current <= this.pageCount - (1 + this.afterCurrent);
		},

		/**
		 * Check if last ellipsis should be visible.
		 */
		hasLastEllipsis() {
			return this.current < this.pageCount - (2 + this.afterCurrent);
		},
		/**
		 * Get near pages, 1 before and 1 after the current.
		 * Also add the click event to the array.
		 */
		pagesInRange() {
			let left = Math.max(1, this.current - this.beforeCurrent);
			if (left - 1 === 2) {
				left--; // Do not show the ellipsis if there is only one to hide
			}
			let right = Math.min(this.current + this.afterCurrent, this.pageCount);
			if (this.pageCount - right === 2) {
				right++; // Do not show the ellipsis if there is only one to hide
			}

			const pages = [];
			for (let i = left; i <= right; i++) {
				pages.push(this.getPage(i));
			}
			return pages;
		}
	},
	methods: {
		changePage(num, event) {
			if (this.current === num || num < 1 || num > this.pageCount) return;
			this.$emit("update:current", num);
			this.$emit("change", num);

			// Set focus on element to keep tab order
			if (event && event.target) {
				this.$nextTick(() => event.target.focus());
			}
		},

		getPage(num, options = {}) {
			return {
				number: num,
				isCurrent: this.current === num,
				click: (event) => this.changePage(num, event),
				disabled: options.disabled || false,
				class: options.class || ""
			};
		}
	}
};
</script>
