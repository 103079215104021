<script lang="ts">
export default {
	name: "ReportsTableShortlistRefferalsRow"
};
</script>

<script lang="ts" setup>
import type { ListingPerformance } from "@/types/dashboard";
import TrafficLight from "./TrafficLight.vue";

interface propsType {
	item: ListingPerformance;
	enabled: boolean;
}

const props = withDefaults(defineProps<propsType>(), {
	item: undefined,
	enabled: false
});
</script>

<template>
	<tr v-if="enabled">
		<td width="250">
			<router-link :to="`/listings/facility/${props.item.listingid}`">
				{{ props.item.listingtitle }}
			</router-link>
		</td>
		<td>{{ props.item.averageupdatefrequency }} days</td>
		<td v-text="props.item.searchresultsfirstpage"></td>
		<td v-text="props.item.matchedreferrals"></td>
		<td>
			<traffic-light
				:percentage="props.item.searchranking"
				data-test="searchranking"
			></traffic-light>
		</td>
		<td>
			<traffic-light
				:percentage="props.item.matchedreferralsscore"
				data-test="matchedreferralsscore"
			></traffic-light>
		</td>
		<td>
			<traffic-light
				:percentage="props.item.vacancyfreshness"
				data-test="vacancyfreshness"
			></traffic-light>
		</td>
	</tr>
	<tr v-else>
		<td>{{ props.item.listingtitle }}</td>
		<td colspan="6">
			<a
				class="gtm-contact-us-mailto"
				href="mailto:sally.power@dailycare.com.au?subject=Listing subscription enquiry"
				>Contact us to subscribe this listing</a
			>
		</td>
	</tr>
</template>
