<script lang="ts">
export default {
	name: "HomePage"
};
</script>

<script lang="ts" setup>
import { onBeforeMount, ref, computed } from "vue";
import userApi from "@/api/user.api";
import type { Report } from "@/types/dashboard";
import { userStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { listingsStore } from "@/stores/listings";
import { useFeaturesStore } from '@/stores/features';

const { user } = storeToRefs(userStore());
const { notifications } = storeToRefs(listingsStore());

const reports = ref<Report[]>([]);

const { featureEnabled } = useFeaturesStore()

onBeforeMount(async () => {
	reports.value = (await userApi.reports.get()) || [];
});
</script>

<template>
	<div v-if="user && user.id">
		<div class="md:flex mb-8 md:mb-0 justify-between items-center">
			<h3 class="title is-3">
				Welcome to DailyAccess, {{ user?.firstname }} {{ user?.lastname }}
			</h3>

			<vacancies-reconfirm-btn :user-id="user.id" />
		</div>

		<app-notifications :notifications="notifications"></app-notifications>

		<reports-native v-if="featureEnabled('native-access-reports')" />
		<reports-hollistics v-else-if="reports.length" :reports="reports" />
		<reports-classic v-else />
	</div>
</template>
