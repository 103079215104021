<script lang="ts">
export default {
	name: "ReportsTableTrafficLight"
};
</script>

<script lang="ts" setup>
import { computed } from "vue";

interface propsType {
	percentage: number | string;
}

const props = withDefaults(defineProps<propsType>(), {
	percentage: 0
});

const tagType = computed(() => {
	if (props.percentage <= 50) {
		return "is-danger";
	}
	if (props.percentage > 50 && props.percentage <= 75) {
		return "is-warning";
	}
	return "is-success";
});
</script>

<template>
	<span :class="['tag', tagType]" v-text="`${props.percentage}%`"></span>
</template>

<style scoped>
.tag {
	min-width: 60px;
}
</style>
