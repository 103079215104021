import type { Organisation } from "@/types/organisation.d";
import { defineStore } from "pinia";
import type { Dashboard } from "@/types/dashboard";
import type { User, UserConfig } from "@/types/user";
import user from "@/api/user.api";
import { listingsStore } from "./listings";
import { organisationStore } from "./organisation";
import { vacanciesStore } from "./vacancies";
import datalayer from '@/plugins/datalayer'
import type { DailyCoreActions } from "@/types/listing";

interface UserState {
	user: User;
	dashboards: Dashboard[];
	config: UserConfig;
	intercom: string | undefined;
	actions: DailyCoreActions[];
}

export const state = (): UserState => ({
	user: {
		email: "",
		firstname: "",
		id: 0,
		image: "",
		jobtitle: "",
		lastname: "",
		mobile: "",
		phone: "",
		phoneisdirect: false
	},
	dashboards: [],
	config: {
		calloutrequests: false,
		sitetourrequests: false,
		terms: false,
		meetingsenabled: false
	},
	intercom: undefined,
	actions: []
});

export const userStore = defineStore("user", {
	state,
	getters: {
		bookingsEnabled: (state): boolean => state.config?.meetingsenabled || false,
		intercomData(state) {
			return state.user
				? {
						app_id: import.meta.env.VITE_APP_INTERCOM_APPID,
						user_id: state.user.id,
						user_hash: state.intercom,
						name: `${state.user.firstname} ${state.user.lastname}`,
						email: state.user.email
				  }
				: undefined;
		},
		gtmData({ user }) {
			return user ? {
				userId: user.id,
				userEmail: user.email,
				userFirstName: user.firstname,
				userLastName: user.lastname,
				userJobTitle: user.jobtitle,
				userPhone: user.phone,
				dnt: navigator.doNotTrack,
				organisationId: this.organisation?.id,
			} : null
		},
		organisation: (): Organisation | undefined => {
			return organisationStore().organisation as Organisation;
		}
	},
	actions: {
		async fetch() {
			const _user = await user.details();
			if (_user) {
				this.user = _user.user || undefined;
				this.dashboards = _user.dashboard || [];
				this.intercom = _user.intercom;
				this.config = await user.config.get(this.user.id);
				this.actions = _user.actions || [];

				if (_user.organisation) {
					organisationStore().$patch({ organisation: _user.organisation });
					organisationStore().prepare();
				}

				await this.loadExtras();
			}
		},
		async loadExtras() {
			const _vacancies = await user.vacancies.get(this.user.id);

			if (_vacancies) {
				vacanciesStore().$patch({ vacancies: _vacancies });
			}

			const _listings = await user.listings.get(this.user.id);

			if (_listings) {
				listingsStore().set(_listings);
			}
			if ((window as any).Intercom) {
				(window as any).Intercom("update", this.intercomData);
			}

			// push the auth info to GTM
			datalayer.push({
				event: "auth",
				updateType: "fetch",
				value: this.gtmData as object
			});
		},
		async update(profile: User) {
			const _user = await user.update(profile);
			if (_user) {
				this.user = _user;
			}
		},
		async agreed() {
			const confirm = { ...this.config, terms: true };
			this.config = await user.config.update(confirm);
		},
		async updatepassword(password: string) {
			await user.password.update(this.user.id, password);
		}
	}
});
