import type {
	ListingPerformanceResponse,
	ShortlistReferralsResponse
} from "@/types/dashboard.d";
import client from "./client";

export const dashboards = {
	listingsperformance: async (
		userID: number,
		start: string | null,
		end: string | null
	): Promise<ListingPerformanceResponse> => {
		const response = await client.post(
			`/metrics/users/${userID}/listingsperformanceextended/table?start=${start}&end=${end}`
		);
		return response.data as ListingPerformanceResponse;
	},
	shortlistrefferals: async (
		userID: number,
		start: string | null,
		end: string | null
	): Promise<ShortlistReferralsResponse> => {
		const response = await client.post(
			`/metrics/users/${userID}/shortlists/table?start=${start}&end=${end}`
		);
		return response.data as ShortlistReferralsResponse;
	}
};

export default dashboards;
