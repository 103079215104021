export enum SortDirection {
	ASC = "asc",
	DESC = "desc"
}
export type SortDef = { name: string; direction: SortDirection };
export type SortBy<Row = any> = string | ((row: Row) => any) | false;
export type ComputedSort = [SortBy[], SortDirection[]];

export type ColumnDef<Row = any, Fields = keyof Row> = {
	name?: string;
	label: string;
	field: Fields;
	sort?: SortBy<Row>;
	headerClass?: string | string[] | { [key: string]: boolean };
	class?: string | string[] | { [key: string]: boolean };
};

export type ComputedColumn<Row = any> = ColumnDef<Row> & {
	sort: SortBy<Row>;
	name: string;
	headerSlot: string
	cellSlot: string
}

export type RowDef<T extends any = any> = T & { [key: string]: any };
