import client from "./client";
import { ListingsCollection } from "@/models/listings/collection";
import type { UserDetails, User, UserConfig, UserListingContact } from "@/types/user";
import type { Report } from "@/types/dashboard";
import type { VacancyType } from "@/types/listing";
import type { ParseFail } from "@/models/parseFail";

export const user = {
	config: {
		get: async (id: number): Promise<UserConfig> => {
			const response = await client.get(`/users/${id}/config`);
			return response.data as UserConfig;
		},
		update: async (config: UserConfig): Promise<UserConfig> => {
			const { userid } = config;
			const response = await client.put(`/users/${userid}/config`, config);
			return response.data as UserConfig;
		}
	},
	details: async (): Promise<UserDetails | undefined> => {
		const response = await client.get(`/users/details`);
		return response.data as UserDetails;
	},
	update: async (user: User): Promise<User> => {
		const response = await client.put(`/users/current/profile`, user);
		return response.data as User;
	},
	password: {
		update: async (id: number, password: string): Promise<undefined> => {
			if (!id || !password) return undefined;
			const response = await client.post(`/users/${id}/password`, { password });
			return response.data;
		}
	},
	listings: {
		get: async (id: number): Promise<ListingsCollection | ParseFail> => {
			const response = await client.get(
				`/users/${id}/listings?with-actions=true`
			);
			return ListingsCollection.parseApi(response.data);
		}
	},
	vacancies: {
		get: async (id: number): Promise<VacancyType[] | []> => {
			const response = await client.get(`/users/${id}/vacancytypes`);
			return response.data || ([] as VacancyType[]);
		}
	},
	reports: {
		get: async (): Promise<Report[]> => {
			const response = await client.get(`/users/dashboardreports`);
			return response.data.reports as Report[];
		}
	},
	listingContacts: {
		get: async (id: number): Promise<UserListingContact[]> => {
			const response = await client.get(`/users/${id}/listingcontacts`);
			return response.data;
		}
	}
};

export default user;
