import { createInjectionState } from '@vueuse/core'
import { Period } from '@/api/reports.api'
import { ref } from 'vue'

const injectionKey = Symbol('reportsFilters')
const [useProvideReportFilters, useInjectReportFilters] = createInjectionState(() => {
  const period = ref<Period>('week')

  return { period }
}, { injectionKey })

function useReportFilters() {
  const filters = useInjectReportFilters()
  if (!filters) throw new Error('useReportFilters is called outside of the native reports')

  return filters
}

export { useProvideReportFilters, useReportFilters }