const TIMEOUT = 1000;

export class Staller {
	public timeout: number = TIMEOUT;

	private startTime: number;

	constructor() {
		this.startTime = Date.now();
	}

	public stop = async () => {
		const end = Date.now();
		const stallTime = this.timeout - (end - this.startTime);
		if (stallTime > 0) {
			await new Promise((resolve) => setTimeout(resolve, stallTime));
		}
	};

	public delay = async (timeout: number = TIMEOUT) => {
		await new Promise((resolve) => setTimeout(resolve, timeout));
	};
}
