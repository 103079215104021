<script lang="ts" setup>
import { ColumnDef, SortDirection } from "@/components/Table/types";
import type { ShortlistReferral } from "@/types/dashboard";
import type { PropType } from "vue";

defineProps({
	data: { type: Array as PropType<ShortlistReferral[]>, default: () => [] }
});

const columns: ColumnDef<ShortlistReferral>[] = [
	{ label: "Title", field: "title" },
	{ label: "Month", field: "month" },
	{ label: "Location searched", field: "location" },
	{ label: "Search radius (km)", field: "searchradius" },
	{ label: "Distance (km)", field: "distance" },
	{ label: "Search criteria", field: "criteria" }
];
</script>

<template>
	<table-advanced
		data-test="shortlist-referrals-table"
		:data="data"
		:columns="columns"
		:sort="{ name: 'title', direction: SortDirection.ASC }"
		:per-page="false"
	>
		<template #title-col="{ row }">
			<router-link :to="`/listings/facility/${row.listingid}`">
				{{ row.title }}
			</router-link>
		</template>
	</table-advanced>
</template>