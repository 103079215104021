export class ParseFail {
	error: string;

	constructor(error: string) {
		this.error = error;
	}

	public static isFailure(thingo: any): thingo is ParseFail {
		return (thingo as ParseFail).error != undefined;
	}
}
