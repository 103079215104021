<template>
	<div v-if="active" class="notification is-info is-light">
		<div class="content">
			<p>
				We've added a notification message to your DailyConnect and DailyCare
				listing(s):
				<span v-for="(listing, index) in notifications" :key="index">
					<router-link
						:to="{
							name: 'listings-facility-id',
							params: listing.params
						}"
						>{{ listing.title }}</router-link
					>
					<span v-if="seperate">
						<span v-if="index === notifications.length - 2"> and </span>
						<span v-else-if="index < notifications.length - 2">, </span>
					</span>
				</span>
			</p>
			<p>
				For more details, call Sally Power 0408 388 089 or by
				<a href="mailto:sally.power@dailycare.com.au?subject=Enquiry"
					>send an email</a
				>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "AppNotifications",
	props: {
		notifications: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		active() {
			return !!this.notifications.length;
		},
		seperate() {
			return this.notifications.length >= 2;
		}
	}
};
</script>

<style lang="scss" scoped>
.notification .content {
	max-width: 650px;
	margin: 0 auto;
	text-align: center;
}
</style>
