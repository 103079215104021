import type { RouteRecordRaw } from "vue-router";

// FIXME: replace the generated email links with the correct frontend routes.

const routes: RouteRecordRaw[] = [
	{
		path: "/site-tours/:id",
		redirect: (to) => ({
			name: "meeting-request-details-type-id",
			params: { type: "site-tour", id: to.params.id }
		})
	}
];

export default routes;
