import router from "./router";
import { createApp, h } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import { authStore } from "@/stores/authentication";
import { userStore } from "@/stores/user";
import VueIntercom from "vue3-intercom";
import VueSmoothScroll from "vue3-smooth-scroll";
import Toast from "vue-toastification";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

import "./css/index.css";
import "vue-toastification/dist/index.css";

const app = createApp({
	mounted() {
		// const { user, intercomData } = userStore();
		this.$intercom.onIsReady(() => {
			this.$intercom.boot();
		});
	},
	render: () => h(App),
	watch: {
		$route() {
			if ((window as any).Intercom) {
				(window as any).Intercom("update");
			}
		}
	}
});

const bugsnagApiKey = import.meta.env.VITE_APP_BUGSNAG_KEY;
const buildVersion = import.meta.env.VITE_APP_BUILDVERSION ?? '0.0.0-dev';
const releaseStage = import.meta.env.VITE_APP_RELEASE_STAGE;

function initBugsnag(vueApp: typeof app, bugsnagApiKey : string, releaseStage : string, bugsnagAppVersion : string) {
	if (bugsnagApiKey && bugsnagApiKey != "") {
		// Creating Bugsnag functionality
		Bugsnag.start({
			apiKey: bugsnagApiKey,
			releaseStage: releaseStage,
			appVersion: bugsnagAppVersion,
			plugins: [new BugsnagPluginVue()],

			// Disable log and error breadcrumbs as it's buggy
			enabledBreadcrumbTypes: ['navigation', 'request', 'user'],

			onError: function (event) {
				const { user } = userStore();
				if (user) {
					event.setUser(
						user.id.toString(),
						user.email,
						`${user.firstname} ${user.lastname}`
					);
					event.addMetadata("user", {
						type: user.type
					});
				}
			}
		});

		const bugsnagVue = Bugsnag.getPlugin("vue");
		if (bugsnagVue) {
			vueApp.use(bugsnagVue);
		}
	}
}

initBugsnag(app, bugsnagApiKey, releaseStage, buildVersion)

const pinia = createPinia();

const toastOptions = {
	showCloseButtonOnHover: true,
	hideProgressBar: true,
	icon: false,
	position: "top-center"
};

app.use(router);
app.use(pinia);
app.use(VueSmoothScroll);
app.use(Toast, toastOptions);

if (import.meta.env.VITE_APP_INTERCOM_APPID != "") {
	app.use(VueIntercom, { appId: import.meta.env.VITE_APP_INTERCOM_APPID });
}

if (localStorage.getItem("token") || window.Cypress) {
	authStore().setToken(localStorage.getItem("token") || "");
	userStore().fetch();
}

app.mount("#app");

// Force a change for access vue3
