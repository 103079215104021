<script lang="ts" setup>
import { getOverallPerformance } from '@/api/reports.api';
import { organisationStore } from '@/stores/organisation';
import { Card, CardContent, CardHeader, CardTitle, Skeleton, SkeletonLoader } from '@dailycare-au/ui'
import { chartOptions, registerChartComponents } from '@dailycare-au/visualise';
import { computedAsync } from '@vueuse/core';
import { CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { Line } from 'vue-chartjs'
import { useReportFilters } from './filters';

registerChartComponents(CategoryScale, LineElement, PointElement, LinearScale)

const { period } = useReportFilters()
const { organisation } = storeToRefs(organisationStore())

const loading = ref(false)
const data = computedAsync(async () => {
  const { data } = await getOverallPerformance(organisation.value!.id, period.value, 'this')
  return data
}, null, { evaluating: loading })


const options = chartOptions('line', {})
const mappedData = computed(() => {
  if (!data.value) return { labels: [], datasets: [] }

  return {
    labels: data.value.scales.find(({ type }) => type === 'x')?.labels ?? [],
    datasets: data.value.datasets
  }
})
</script>

<template>
  <Card class="col-span-full">
    <CardHeader>
      <CardTitle>{{ data?.title }}</CardTitle>
    </CardHeader>

    <CardContent>
      <div class="h-[500px] w-full">
        <SkeletonLoader :loading="loading">
          <Line :data="mappedData" :options="options" class="size-full" />

          <template #skeleton>
            <Skeleton class="size-full" />
          </template>
        </SkeletonLoader>
      </div>
    </CardContent>
  </Card>
</template>