<script lang="ts" setup>
import { ColumnDef, RowDef, SortDirection } from "@/components/Table/types";
import type { ListingPerformance } from "@/types/dashboard";
import type { PropType } from "vue";
import TableRow from "./TableRow.vue";

defineProps({
	data: { type: Array as PropType<ListingPerformance[]>, default: () => [] }
});

const columns: ColumnDef<ListingPerformance>[] = [
	{ label: "Title", field: "listingtitle" },
	{ label: "Avg. update frequency", field: "averageupdatefrequency" },
	{ label: "Search results first page", field: "searchresultsfirstpage" },
	{ label: "Matched referrals", field: "matchedreferrals" },
	{ label: "Search ranking score", field: "searchranking" },
	{ label: "Matched referrals score", field: "matchedreferralsscore" },
	{ label: "Vacancy freshness score", field: "vacancyfreshness" }
];

const performanceEnabled = (plan: string) =>
	plan.includes("Subscription") || plan === "Trial";
</script>

<template>
	<table-advanced
		class="listing-performance"
		:data="data"
		:columns="columns"
		:sort="{ name: 'listingtitle', direction: SortDirection.ASC }"
		:per-page="false"
	>
		<template #row="{ row }: { row: RowDef<ListingPerformance> }">
			<table-row :item="row" :enabled="performanceEnabled(row.listingplan)"
			></table-row>
		</template>
	</table-advanced>
</template>
