import { is_date_set } from "@/plugins/format";
import axios, { type AxiosError, type AxiosResponse } from "axios";
import consola from "consola";
// import bugsnagClient from "../utils/bugsnag";

const isProduction = process.env.NODE_ENV === "production";
const isoDateFormat =
	/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const getAuthToken = () => "BEARER " + window.localStorage.getItem("token");

const client = axios.create({
	baseURL: import.meta.env.VITE_APP_SERVICES || "http://localhost:9009",
	timeout: 40000
});

function isIsoDateString(value: any): boolean {
	return value && typeof value === "string" && isoDateFormat.test(value);
}

// If the response includes a date we convert it to a Date object
function handleDates(body: any) {
	if (body === null || body === undefined || typeof body !== "object")
		return body;

	for (const key of Object.keys(body)) {
		const value = body[key];
		if (isIsoDateString(value))
			body[key] = is_date_set(value) ? new Date(value) : undefined;
		else if (typeof value === "object") handleDates(value);
	}
}

// Checking over response for Dates.
client.interceptors.response.use((originalResponse) => {
	handleDates(originalResponse.data);
	return originalResponse;
});

client.interceptors.request.use((config) => {
	if (config.headers) {
		config.headers.Authorization = getAuthToken();
	}
	return config;
});

// if (!isProduction) {
client.interceptors.request.use((config) => {
	if (!isProduction && config && config.method) {
		consola.info(
			`[${config.method.toUpperCase()}]`,
			`${config.baseURL}${config.url}`,
			config.data || ""
		);
	}
	return config;
});
client.interceptors.response.use(
	(response: AxiosResponse) => {
		if (!isProduction && response && response.config.method) {
			consola.success(
				`[${response.config.method.toUpperCase()}]`,
				`[${response.status} ${response.statusText}]`,
				// `[${process.server ? `SSR` : `CLIENT`}]`,
				`${response.config.baseURL}${response.config.url}`
			);
		}
		return response;
	},
	(error: AxiosError) => {
		if (!isProduction && error && error.response && error.config.method) {
			consola.error(
				`[${error.response.status} ${error.response.statusText}]`,
				`[${error.config.method.toUpperCase()}]`,
				`${error.config.baseURL}${error.config.url}`
			);
		}
		if (error && error.response) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error);
	}
);
// }

export default client;
