import type { VacancyType } from "@/types/listing";
import { orderBy, sumBy } from "lodash";

export const lastUpdate = (
	list: VacancyType[],
	listingid: number
): Date | undefined => {
	const dates = orderBy(
		list
			.filter((vacancy) => vacancy.listingid === listingid)
			.map((vacancy) => vacancy.updated)
	);

	// If no dates are here then its safe to say there is no vacancies
	// so will therefore show an invalid date. So we return a dash to
	// signify no date is assigned to the listing
	if (!dates.length) {
		return undefined;
	}

	const date = dates.pop();

	return date as Date;
};

export const vacancyTally = (
	list: VacancyType[],
	listingid: number,
	type = "available"
) => {
	const total = sumBy(
		list.filter((listing) => listing.listingid === listingid),
		type
	);
	return total > 0 ? total : null;
};
