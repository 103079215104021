<script lang="ts" setup>
import { FieldControl, FieldItem, FieldLabel, FormField, Radio, RadioGroup } from '@dailycare-au/ui'
import { useProvideReportFilters } from './filters';

const periods = [
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Quarter', value: 'quarter' },
  { label: 'Year', value: 'year' },
]

const { period } = useProvideReportFilters()
</script>

<template>
  <div class="native-reports grid grid-cols-4 gap-4">
    <FormField v-model="period" v-slot="{ componentField }" name="period" type="radio">
      <RadioGroup v-bind="componentField" class="flex gap-4 items-center col-span-full">
        <FieldItem v-for="period of periods" :key="period.value" inline>
          <FieldControl>
            <Radio :value="period.value" />
          </FieldControl>
          <FieldLabel>{{ period.label }}</FieldLabel>
        </FieldItem>
      </RadioGroup>
    </FormField>

    <ReportsNativeOverview />

    <ReportsNativeOverallPerformance />
  </div>
</template>

<style lang="scss">
  /**
   * unfortunately required to override bulmas forced utility classes
   * scoping also doesn't work here...
  */

  div.native-reports {
    .p-6 { padding: 1.5rem !important; }
    .pt-0 { padding-top: 0 !important; }
  }
</style>